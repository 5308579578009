import React from 'react';
import { Helmet } from 'react-helmet';
import { LinkContainer } from 'react-router-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ReactMarkdown from 'react-markdown';

const article = `
# Country Dance and Quadrille

## Foreward

_Terry Moylan_

At the end of the nineteenth century the Gaelic League caused an enormous fuss about the new dances, the Quadrilles, coming into Ireland, and denounced them as un-Irish or even anti-Irish because they were believed to have originated in England. This irrational xenophobia was, however, merely a repetition of the reaction in England several decades earlier when the same dances were denounced as un-English interlopers from France. The following piece of verse by Thomas Moore (1779-1852) catches the mood of offended national sensibilities very well. If some of the names and terms were changed it could well be the cry of some Irish Irelander of 1900.

## A defence of the old dancing
### _Thomas Moore_

One night the nymph called COUNTRY DANCE-
(Whom folks, of late, have used so ill,
Preferring a coquette from France,
That mincing thing, Mamselle QUADRILLE) [[1]](#quadrille)

Having been chas'd from London down
To that most humble haunt of all
She used to grace-a Country Town-
Went smiling to the New-Year's Ball.

"Here, here, at least," she cried, "though driven
From London's gay and shining tracks-
Though, like a Peri [[2]](#peri) cast from heaven,
I've lost, for ever lost, Almack's- [[3]](#almacks)

"Though not a London Miss alive
Would now for her acquaintance own me;
And spinsters, even, of forty-five,
Upon their honours ne'er have known me;

"Here, here, at least, I triumph still,
And-spite of some few dandy Lancers, [[4]](#lancers)
Who vainly try to preach Quadrille-
See nought but true-blue Country Dancers.

"Here still I reign, and, fresh in charms,
My throne, like Magna Charta, raise
'Mong sturdy, freeborn legs and arms,
That scorn the threatened chaîne Anglaise." [[5]](#chaine-anglaise)

'Twas thus she said, as 'mid the din
Of footmen, and the town sedan,
She lighted at the King's Head Inn, [[6]](#kings-head-inn)
And up the stairs triumphant ran.

The Squires and their Squiresses all,
With young Squirinas, just come out, [[7]](#come-out)
And my Lord's daughters from the Hall,
(Quadrillers, in their hearts, no doubt)-

All these, as light she tripp'd up stairs,
Were in the cloak-room seen assembling-
When, hark! some new, outlandish airs,
From the First Fiddle, set her trembling.

She stops-she listens-can it be?
Alas, in vain her ears would 'scape it-
It is "Di tanti palpiti" [[8]](#di-tanti-palpiti)
As plain as English bow can scrape it.

"Courage!" however-in she goes,
With her best, sweeping country grace;
When, ah too true, her worst of foes,
QUADRILLE, there meets her face to face.

Oh for the lyre, or violin,
Or kit [[9]](#kit) of that gay Muse, Terpsichore, [[10]](#terpsichore)
To sing the rage these nymphs were in,
Their looks and language, airs and trickery.

There stood QUADRILLE, with cat-like face
(The beau-ideal of French beauty)
A band-box thing, all art and lace
Down from her nose-tip to her shoe-tye.

Her flounces, fresh from Victorine- [[11]](#victorine)
From Hippolyte [[12]](#hippolyte) her rouge and hair-
Her poetry from Lamartine- [[13]](#lamartine)
Her morals from-the Lord knows where.

And, when she danc'd-so slidingly,
So near the ground she plied her art,
You'd swear her mother-earth and she
Had made a compact ne'er to part.

Her face too, all the while, sedate,
No signs of life or motion showing,
Like a bright pendule's [[14]](#pendule) dial-plate-
So still, you'd hardly think 'twas going.

Full fronting her stood COUNTRY DANCE-
A fresh, frank nymph, whom you would know
For English, at a single glance-
English all o'er, from top to toe.

A little gauche, 'tis fair to own,
And rather given to skips and bounces;
Endangering thereby many a gown,
And playing, oft, the devil with flounces.

Unlike Mamselle-who would prefer
(As morally a lesser ill)
A thousand flaws of character,
To one vile rumple of a frill.

No rouge did She of Albion wear;
Let her but run that two-heat race
She calls a Set, not Dian e'er
Came rosier from the woodland chase.

Such was the nymph, whose soul had in't
Such anger now-whose eyes of blue
(Eyes of that bright, victorious tint,
Which English maids call "Waterloo")- [[15]](#waterloo)

Like summer lightnings, in the dusk
Of a warm evening, flashing broke,
While-to the tune of "Money Musk," [[16]](#money-musk)
Which struck up now-she proudly spoke

"Heard you that strain-that joyous strain
'Twas such as England loved to hear,
Ere thou, and all thy frippery train,
Corrupted both her foot and ear-

"Ere Waltz, [[17]] that rake from foreign lands,
Presumed, in sight of all beholders,
To lay his rude, licentious hands
On virtuous English backs and shoulders- [[18]](#virtuous-english-backs-and-shoulders)

"Ere times and morals both grew bad,
And, yet unfleec'd by funding blockheads,
Happy John Bull [[19]](#john-bull) not only had,
But danc'd to, "Money in both pockets." [[20]](#money-in-both-pockets)

"Alas, the change ! Oh, L-d-y, [[21]](#l-d-y)
Where is the land could 'scape disasters,
With such a Foreign Secretary,
Aided by Foreign Dancing, Masters?

"Woe to ye, men of ships and shops! [[22]](#ships-and-shops)
Rulers of day-books and of waves!
Quadrill'd, on one-side, into fops,
And drill'd, on t'other, into slaves! [[23]](#drilld-into-slaves)

"Ye, too, ye lovely victims, seen,
Like pigeons, truss'd for exhibition,
With elbows, à la crapaudine, [[24]](#a-la-crapaudine)
And feet in-God knows what position;

"Hemm'd in by watchful chaperons,
Inspectors of your airs and graces,
Who intercept all whisper'd tones,
And read your telegraphic faces;

"Unable with the youth adored,
In that grim cordon of Mammas,
To interchange one tender word,
Though whisper'd but in queue de chats. [[25]](#queue-de-chats)

"Ah did you know how blest we rang'd,
Ere vile Quadrille usurp'd the fiddle-
What looks in setting [[26]](#setting) were exchanged,
What tender words in down the middle; [[27]](#down-the-middle)

"How many a couple, like the wind,
Which nothing in its course controls,
Left time and chaperons far behind,
And gave a loose to legs and souls;

"How matrimony throve-ere stopp'd
By this cold, silent, foot-coquetting-
How charmingly one's partner popp'd
The important question in poussetting. [[28]](#poussetting)

"While now, alas! no sly advances-
No marriage hints-all goes on badly;
'Twixt Parson Malthus [[29]](#parson-malthus) and French Dances,
We, girls, are at a discount sadly.

"Sir William Scott (now Baron Stowell) [[30]](#sir-william-scott)
Declares not half so much is made
By Licences-and he must know well-
Since vile Quadrilling spoil'd the trade."

She ceas'd-tears fell from every Miss-
She now had touch'd the true pathetic:-
One such authentic fact as this
Is worth whole volumes theoretic.

Instant the cry was "Country dance!"
And the maid saw, with brightening face,
The Steward of the night advance,
And lead her to her birthright place.

The fiddles, which awhile had ceased
Now tuned again their summons sweet,
And, for one happy night at least,
Old England's triumph was complete.

----------
#### [1] Quadrille
Square dance for four couples. According to Captain Gronow (Reminiscences & Recollections, being Anecdotes of the Camp, Court, Clubs and Society 1810-1860, London 1889), it was in 1815 that the Quadrilles were performed in Britain for the first time in Almack's of Mayfair.

#### [2] Peri
Probably peridot, a gemstone.

#### [3] Almack's
Almack's Assembly Rooms, in Mayfair, London, were built in 1765 by the Scot William Macall. The reversal of the two syllables of his surname provided the name for the establishment.

#### [4] Lancers
A set of quadrilles composed by a M. Duval, and first advertised in the Dublin Evening Post in May 1817.

#### [5] Chaine Anglaise
A quadrille movement in which to opposite couples pass through and dance into place. See movement 50 on Thomas Wilson's diagram of quadrille movements from his Quadrille & Cotillion Panorama (London 1822).

#### [6] King's Head Inn
A popular name for inns and taverns in rural England.

#### [7] come out
In the 18th/19th centuries, to "come out" meant that a young lady had started taking part in social gatherings.

#### [8] Di tanti palpiti
"So many sighs", an aria from the opera Tancredi by Rossini. It was performed for the first time in 1813.

#### [9] kit
A slender-bodied fiddle designed for easy portability and used in the main by dancing masters.

#### [10] Terpsichore
The Greek muse of dance.

#### [11] Victorine
Possibly the play Le Mariage de Victorine by George Sand (1806-1876), first presented in 1851, but more probably John Buckstone's Victorine.

#### [12] Hippolyte
In Roman mythology the daughter of Mars, and the queen of the Amazons. The reference may refer to the opera Hippolyte et Aricie, by Jean-Philippe Rameau & Abbé S. J. Pellegrin, which was first performed in 1733.

#### [13] Lamartine
Alphonse Lamartine (1790-1869), French romantic poet, author of Poetic Meditations (1820).

#### [14] pendule
A pendulum clock.

#### [15] Waterloo
The battle of Waterloo was fought in May 1815. Moore invokes the memory of the French defeat at the hands of the British.

#### [16] Money Musk
A strathspey tune named after a place in Scotland, popular from the late eighteenth century.

#### [17] Waltz
The Waltz originated in Austria in the 18th century and very slowly gained acceptance in England. It was a source of scandal for many due to the manner in which the man held his partner-such an intimate dancing position was unprecedented at the time. It may be said to have finally "arrived" in 1816 when it received the approval of the Prince Regent.

#### [18] virtuous English backs and shoulders
When the waltz was included on the programme of the Prince Regent's ball in 1816, the Times thundered:

We remarked with pain that the indecent foreign dance called the "waltz" was introduced (we believe for the first time) at the English Court on Friday last. This is a circumstance not to be passed over in silence. National morals depend on national habits : and it is quite sufficient to cast one's eyes on the voluptuous intertwining of the limbs, and close compressure of the bodies, in their dance, to see that it is indeed far removed from the modest reserve which has hitherto been considered distinctive of English females. So long as this foul display was confined to prostitutes and adultresses we did not think it deserving of notice; but now that it is attempted to be forced upon the respectable classes of society by the evil example of their superiors, we feel it a duty to warn every parent against exposing his daughter to so fatal a contagion.

#### [19] John Bull
A nickname for an Englishman, Englishmen collectively, or England.

#### [20] Money in both pockets
A dance tune in 6/8 (jig) time.

#### [21] L-d-y
Lord Londonderry was the British Foreign Secretary in the 1820s.

#### [22] ships and shops
Probably a conflation of Napoleon's remark about the English being "a nation of shopkeepers" with English pride in their naval might.

#### [23] drill'd into slaves
In Moore's time the English navy was manned to a great extent by men who had been press-ganged into it.

#### [24] à la crapaudine
A way for trussing a fowl for cooking.

#### [25] queue de chats
Another name for the Promenade movement in dancing.

#### [26] setting
A movement in dancing when the dancer turns away from their own partner and dances facing the nearest dancer of the opposite sex.

#### [27] down the middle
A dance movement where a couple moves through two lines of dancers.

#### [28] poussetting
In a demi-poussette, two gentlemen join hands with the opposite ladies and pass around each other to their places.

#### [29] Parson Malthus
Reverend Thomas Malthus (1766-1834) whose Essay on the Principle of Population was published in 1798.

#### [30] Sir William Scott
William Scott (1745-1836) was created the 1st Baron Stowell in 1821.
`;

const CountryDance = () => {
	return (
		<div className="archive">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Brooks Academy - Country Dance and Quadrille: A defence of the old dancing
				</title>
			</Helmet>
			<h1>
				<Breadcrumb>
					<LinkContainer to="/archive">
						<Breadcrumb.Item>Archive</Breadcrumb.Item>
					</LinkContainer>
					<Breadcrumb.Item active>
						Country Dance and Quadrille
					</Breadcrumb.Item>
				</Breadcrumb>
			</h1>

			<div className="box-content box">
				<ReactMarkdown>
					{article}
				</ReactMarkdown>
			</div>
		</div>
	);
};

export default CountryDance;
